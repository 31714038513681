<template>
  <div>123</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped></style>
