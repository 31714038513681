<template>
  <div>
    <div class="alipay">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>支付宝电子水票</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button
          type="success"
          style="height: 36px"
          @click="reload"
          size="mini"
        >
          <i style="font-size: 14px" class="el-icon-refresh-right"></i>
        </el-button>
      </div>
      <div class="tableInput">
        <span>创建日期：</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date1"
          style="width: 12%"
        ></el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date2"
          style="width: 12%"
        ></el-date-picker>
        <el-select v-model="status" style="width: 8% !important; margin: 0 5px">
          <el-option
            v-for="item in statuses"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="isPackage"
          style="width: 8% !important; margin: 0 5px"
        >
          <el-option
            v-for="item in isPackages"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select v-model="type" style="width: 8% !important; margin: 0 5px">
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="searchContent"
          placeholder="请输入内容"
          style="width: 15%; margin-right: 5px"
        ></el-input>
        <el-button type="success"><i class="el-icon-search"></i>查询</el-button>
      </div>
      <div class="tableBtn">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addTable"
          size="small"
          >新增</el-button
        >
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :border="true"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="水票ID" prop="id"> </el-table-column>
          <el-table-column prop="name" label="图片"> </el-table-column>
          <el-table-column prop="address" label="创建日期"> </el-table-column>
          <el-table-column prop="address" label="名称"> </el-table-column>
          <el-table-column prop="address" label="单位"> </el-table-column>
          <el-table-column prop="address" label="购买数量"> </el-table-column>
          <el-table-column prop="address" label="赠送数量"> </el-table-column>
          <el-table-column prop="address" label="售卖价格"> </el-table-column>
          <el-table-column prop="address" label="是否套票"> </el-table-column>
          <el-table-column prop="address" label="有效期类型"> </el-table-column>
          <el-table-column prop="address" label="使用开始时间">
          </el-table-column>
          <el-table-column prop="address" label="使用结束时间">
          </el-table-column>
          <el-table-column prop="add" label="使用说明"> </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom">
          <div class="bottom-left">
            显示第 {{ start }} 至 {{ end }} 项结果，共 {{ tableDataLength }} 项
          </div>
          <div class="bottom-right">
            <div class="right-btn" @click="homeClick">首页</div>
            <div class="right-btn" @click="upClick">上页</div>
            <el-pagination
              background
              layout="pager"
              :total="tableDataLength"
              :current-page="page"
              :page-size="length"
              :pager-count="5"
              @current-change="currentChange"
            ></el-pagination>
            <div class="right-btn" @click="nextClick">下页</div>
            <div class="right-btn" @click="endClick">末页</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="新增水票" :visible.sync="addShow" width="30%">
      <AddAlipay />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AddAlipay from "./child/addAlipay.vue";
export default {
  components: { AddAlipay },
  data() {
    return {
      dateTime: {
        data1: "",
        data2: "",
      },
      types: [
        { label: "状态", value: "" },
        { label: "在售", value: 0 },
        { label: "下架", value: 1 },
      ],
      type: "状态",
      isPackage: "是否套票",
      isPackages: [
        { label: "是否套票", value: "" },
        { label: "套票", value: 0 },
        { label: "普票", value: 1 },
      ],
      status: "有效期类型",
      statuses: [
        { label: "有效期类型", value: "" },
        { label: "绝对时间", value: 0 },
        { label: "相对时间", value: 1 },
        { label: "长期有效", value: 2 },
      ],
      searchContent: "",
      tableData: [],
      start: 0,
      end: 0,
      tableDataLength: 0,
      page: 1,
      length: 10,
      addShow: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    reload() {
      console.log("刷新");
    },
    addTable() {
      this.addShow = true;
    },
    handleSelectionChange(data) {
      console.log(data);
    },
    handleEdit(row) {
      console.log(row);
    },
    handleDelete(row) {
      console.log(row);
    },
    homeClick() {
      console.log("首页");
    },
    upClick() {
      console.log("上页");
    },
    nextClick() {
      console.log("下页");
    },
    endClick() {
      console.log("末页");
    },
    currentChange(page) {
      console.log(page);
    },
  },
};
</script>
<style lang="less" scoped>
.alipay {
  .breadcrumb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 5px 20px;
  }
  .tableInput {
    padding: 10px 20px;
  }
  .tableBtn {
    margin: 10px 20px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #eee;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    &-left {
      font-size: 14px;
      color: #666666;
    }
    &-right {
      display: flex;
      align-items: center;
      .right-btn {
        padding: 3px 8px;
        border: 1px solid #cbcbcb;
        font-size: 14px;
        color: #666666;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
